<template>
  <div class="home-container">
    <!-- Hero Section with Image/Graphic Placeholder -->
    <section class="hero-section">
      <div>
        <div class="hero-content">
          <h1></h1>
          <!-- Placeholder for a Hero Graphic -->
          <img :src="heroImage" alt="Sunlight Illustration">
        </div>
      </div>
    </section>

    <!-- Features Section -->
    <section class="features-section">
      <div class="feature" v-for="feature in features" :key="feature.id">
        <div class="feature-image"> <img :src="feature.image" :alt="feature.title">
        </div>
        <div class="feature-text">
          <h3><div v-html="feature.title"></div></h3>
          <div v-html="feature.description"></div>
        </div>
      </div>
    </section>

    <!-- Call to Action Section -->
    <!-- <section class="cta-section">
      <h2>Join Our Community</h2>
      <p>Stay informed and healthy.</p>
      <button @click="joinNow">Join Now</button>
    </section> -->

  </div>
</template>
  
<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AppHome',
  data() {
    return {
      heroImage: require('@/assets/hero5.png'), // Replace with your image path
      features: [
        {
          id: 1,
          title: 'About DSunLife',
          description: `DSunlife is designed to assist users in managing their sun exposure to optimize vitamin D
            production while minimizing the risks associated with excessive sun exposure, such as sunburn,
            skin damage and skin cancer.
            <p>Its main benefit is showing that only a relatively short period of sun exposure is required
            to obtain and maintain sufficient vitamin D levels.`,
          image: require('@/assets/logo2_small.png'),
        },
         {
          id: 1,
          title: 'How To Use The App',
          description: `<ol><li><strong>Select Skin Shade:</strong> Pick a skin shade closest to yours.</li>
            <li><strong>Enter Age & Skin Exposure:</strong> Input your age and estimate the amount of skin exposed (front and back).</li>
            <li><strong>Use Timer for Sunbathing:</strong> Start the timer, sunbathe on one side, then restart the timer and flip to the other side.</li>
            <li><strong>The App Calculates Safe Sun Time:</strong> The app considers your location, time, date, skin type, and age to determine safe sun exposure times.</li>
            <li><strong>Sunbathing Guidance:</strong> The app advises if it's too late in the day or too close to winter for effective sunbathing and Vitamin D production.</li></ol>`,
          image: require('@/assets/dsunlife.app.jpg'),
        },
         {
          id: 1,
          title: 'The Science',
          description: `The app calculates UVB normal and diffuse irradiance using a model developed by Bird [1]. 
            This model aligns well with the more advanced libRadTran model[2]. The app's focus is on UVB irradiance as only UVB produces vitamin D within the skin.
            There is also a strong association between chronic sunburns from UVB exposure and the subsequent risk of skin cancer [3]. To estimate safe 
            sun exposure times, the app employs the minimal erythema dose (MED) concept. MED is the amount of sun exposure that causes minimal skin reddening
            several hours later.
            <p>While adequate vitamin D levels have several benefits [4,6], supplementation is recommended to reduce skin cancer risk [7]. However, there may be added
            benefits of sun exposure [8]. The app uses MED to determine the maximum sun exposure that minimizes the likelihood of nonmelanoma skin cancer.
            Different MED doses for various skin types are based on research by Dornelles et al [3]. Additionally, to calculate vitamin D production in the skin,
            the app utilizes the relationship identified by Robert P. Heaney, as discussed by Armas et al [5].<br><br>
            <strong>References</strong><br>
            [1] R.E. Bird, Solar Energy, <strong>32</strong>, 461-471, 1984.<br>
            [2] libRadTran, www.libradtran.org.<br>
            [3] S. Dornelles et al, Photochemistry and Photobiology, <strong>79</strong>, 540-544, 2004.<br>
            [4] M.F. Holick, N Engl J Med, <strong>357</strong>, 266-281, 2007.<br>
            [5] L.A.G. Armas et al, J Am Acad Dermatol, <strong>57</strong>, 588-593, 2007.<br>
            [6] J. Wacker and M.F. Holick, Dermato-Endocrinology <strong>5</strong>, 51-108, 2013.<br>
            [7] J. D'Orazio et al, Int J Mol Sci, <strong>14</strong> 12222-12248, 2013.<br>
            [8] D. Liu et al, J Invest Dermatol, <strong>134</strong>, 1839–1846, 2014.<br>
            `,
          image: require('@/assets/library.png'),
        },
        // ... other features
      ]
    };
  },
  methods: {
    joinNow() {
      // Implement your join now action
    }
  }
});
</script>

<style lang="css"> /* NB scoped want work with v-html and li see stack overflow */

ul, ol {
  padding-left: 0; /* Removes padding from the left side of the list */
  list-style-type:decimal; /* Removes the default list item markers (like numbers or bullets) */
}

li {
  margin-left: 0; /* Removes additional margin from the left side of list items, if any */
}

</style>
  
<style lang="css" scoped> 

.hero-section {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero-content {
  /* position: relative; */
  width: 100%;
  /* max-width: 1600px; */
  /* Adjust based on your preference */
  /* text-align: center; */
  margin: 0 auto;
}

.hero-content img {
  /* position: absolute; */
  width: 100%;
  /* max-width: 1600px; */
  object-fit: cover;
}

.hero-content h1,
.hero-content p {
  position: absolute;
  top: 0%;
  width: 100%;
  left: 0;
  margin: 0;
  z-index: 1;
  /* Ensures text is above the image */
  color: #ffffff;
  font-size: 2vmax;
}

.home-container {
  text-align: center#000;
}

.features-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #404040;
  background-size: cover;
}
.feature:nth-child(odd) {
  flex-direction: row;
}
.feature:nth-child(even) {
  flex-direction: row-reverse;
}
.feature:nth-child(even) .feature-image {
  display: flex;
  justify-content: flex-end;
}


.feature {
  padding-top: 10%;
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  margin-bottom: 0px; /* Adjust as needed */
  /* justify-content: flex-start; */
  width: 60%;
}

.feature-image { 
  flex: 0 0 50%;
}
.feature-text{
  flex: 0 0 60%;
}


.feature-image img {
  padding-top: 20px;
  /* padding-left: 20px; */
  width: 70%;
  max-width: 70%; /* Ensures the image is responsive */
  height: auto;
}

.feature-text {
  padding-left: 10px; /* Adds some padding between image and text */
  text-align: left;
  color: #ffffff;
}

/* Responsive adjustments if needed */
@media (max-width: 768px) {
  .feature {
    width: 90%;
  }
  .feature-image {
    flex: 0 0 30%;
  }
  .feature-text {
    flex: 0 0 70%;
  }

  /* .feature-text {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
  } */
  .feature-image img { 
    width: 100%;
    /* height: auto; */
  }
  .feature-text{
    width: 100%;
  }
}
.cta-section {
  background-color: #d3d3d3;
  padding: 20px;
}

</style>
  