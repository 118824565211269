<template>
  <nav>
    <!-- Navigation Content -->
    <div>Welcome to DSunLife</div>
  </nav>
</template>

<script>
export default {
  name: 'AppNavBar'
};
</script>

<style scoped>
nav {
  text-align: center;
  font-size: xx-large;
  font-weight: 800;
  color: #ffffff;
  background-color: #404040;
  /* Navbar styles */
}
</style>
