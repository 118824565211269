<template>
  <div class="content-container">
    <Navbar />
    <router-view />
    <Footer />
  </div>
</template>

<script>
// import Navbar from './components/Navbar.vue'
import Footer from './components/Footer.vue'
import Navbar from './components/Navbar.vue'

export default {
  name: 'App',
  components: {
    Navbar,
    Footer
  },
  mounted() {
    document.body.style.backgroundColor = '#404040'
  },
}
</script>

<style>
/* Global styles */
.content-container {
  max-width: 1600px; /* Set your desired width */
  margin: 0 auto;
  /* padding: 0px; */
}
</style>