<template>
  <footer>
    <!-- Footer Content -->
    <div>
      <p>&copy; 2023 Dsunlife. All Rights Reserved.</p>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'AppFooter'
};
</script>

<style scoped>
  footer {
    margin-top: 40px;
    padding: 20px;
    background-color: #333;
    color: white;
    text-align: center;
  }
</style>
